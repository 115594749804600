<template>
  <div class="addicon">
    <h1>ホーム画面へのアイコン作成方法</h1>
    <a href="#iPhone">・iPhoneの方</a><br>
    <a href="#android">・Androidの方</a><br>
    <h2 id="iPhone">- iPhone -</h2>
    ①赤枠のアイコンをタップ<br>
    <img src="../assets/01-iPhone.png"><br>
    <br>
    ②「ホーム画面に追加」をタップ<br>
    <br>
    <img src="../assets/02-iPhone.png"><br>
    <br>
    ③「保存」をタップ<br>
    <br>
    <img src="../assets/03-iPhone.png"><br>
    <br>
    ④ホーム画面にアイコンが作成されます<br>
    <br>
    <img src="../assets/04-iPhone.png"><br>
    <br>

    <h2 id="android">- Android -</h2>
    ①赤枠のアイコンをタップ<br>
    <br>
    <img src="../assets/01-Android.png"><br>
    <br>
    ②「ホーム画面に追加」をタップ<br>
    <br>
    <img src="../assets/02-Android.png"><br>
    <br>
    ③「追加」をタップ<br>
    <br>
    <img src="../assets/03-Android.png"><br>
    <br>
    ④「自動的に追加」をタップ<br>
    <br>
    <img src="../assets/04-Android.png"><br>
    <br>
    ⑤ホーム画面にアイコンが作成されます<br>
    <br>
    <img src="../assets/05-Android.png"><br>
    <br>

  </div>
</template>

<style lang="scss" scoped>
    img {
        width: 375px;
        max-width: 100%;
        box-sizing: border-box;
    }
</style>

<template>
  <div class="main">
    <!-- 選択された店舗の名前を表示する。とりあえず「居酒屋」と表示する。 -->
    <!-- 店舗名を表示する -->
    <p>{{ name }}</p>
    <!-- お店のジャンルキャッチを表示する -->
    <p>{{ genreCatch }}</p>
    <!-- 店内写真を表示する - -->
    <p><img :src="photo"></p>
    <!-- お店のキャッチを表示する -->
    <p>{{ storeCatch }}</p>
    <!-- 平均ディナー予算を表示する -->
    <p v-if="budgetAverage != ''">平均金額：{{ budgetAverage }}</p>
    <!-- お店までの距離を表示する -->
    <p v-if="showDistance != ''">お店までの距離：{{ showDistance }}</p>
    <!-- 「ここにする」ボタンを表示する。 -->
    <div class="width">
      <button @click="clickGotoMap" class="clickButton" v-if="buttonShowFlag">ここにする</button>
      <br>
      <!-- 「他のお店にする」ボタンを表示する。 -->
      <button @click="clickOtherStore" class="clickButton" v-if="buttonShowFlag">他のお店にする</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      // データを用意する
      storeId: "",
      name: "",
      address: "",
      genreCatch: "",
      storeCatch: "",
      budgetAverage: "",
      photo: "",
      // currentLat: 35.851178,
      // currentLng: 139.318571,
      currentLat: 0,
      currentLng: 0,
      range: 1,
      storeLat: 0,
      storeLng: 0,
      showDistance: 0,
      storeData: [],
      viewNum:0,
      buttonShowFlag: false,
      userName: "",
      choiceResultId: "",
    };
  },
  mounted() {
      //ページが開かれた場合、位置情報、店舗データを取得する。
      this.getGeolocation();
  },
  methods: {
    clickGotoMap() {
      //ログインしているユーザー情報を取得する。
      var update_flag = 0;
      if(this.choiceResultId != ""){
        update_flag = 1;
      }
      Auth.currentAuthenticatedUser().then(user => {
        //検索結果登録APIを呼び出す。
        let apiurl = "https://jov6ym4yl7.execute-api.ap-northeast-1.amazonaws.com/prod/choice-result";
        axios.post(apiurl, {choiceResultId: this.choiceResultId, user_name: user.username, choice_flag: "1" , store_id: this.storeId , update_flag: update_flag} , {headers:{"Authorization": user.signInUserSession.idToken.jwtToken}}).then((response) => {
          console.log(response);
        }).catch(err => console.log(err));
      }).catch(err => console.log(err));

      //「ここにする」ボタンが押された場合の処理を押す。
      this.$router.push({path: '/map', query: {storeId: this.storeId}});
    },
    clickOtherStore(){
      //「他のお店にする」ボタンが押された場合の処理を押す。
      //このボタンが押された場合、
      // 店舗取得時に作成したリストの中のデータの中の2,3,n件目（ボタンを押されるたびに変わる）のデータを使用し、このページを再表示する。
      if(this.storeData.length > 0 && this.storeData.length > this.viewNum){
        //ホットペッパーAPIから店舗データを取得できている場合、リストの次の店舗のデータを設定する。
        this.setStore();

      }else{
        // 取得範囲を5まで1段階ずつ上げていく
        if (this.range < 5){
          this.range++;
        }
        //店舗データが取得できていない場合、位置情報、店舗データを取得する。
        this.getGeolocation();
      }
      //ログインしているユーザー情報を取得する。
      Auth.currentAuthenticatedUser().then(user => {
        //検索結果登録APIを呼び出す。
        let apiurl = "https://jov6ym4yl7.execute-api.ap-northeast-1.amazonaws.com/prod/choice-result";
        axios.post(apiurl, { user_name: user.username, choice_flag: "0" , store_id: this.storeId , update_flag: 0 } , {headers:{"Authorization": user.signInUserSession.idToken.jwtToken}}).then((response) => {
          this.choiceResultId = response.data.id;
          console.log(response);
        }).catch(err => console.log(err));
      }).catch(err => console.log(err));
    },
    getGeolocation(){
      //現在位置を取得する。
      // var geoOptions = {
      //   enableHighAccuracy: true, //精度を高める
      //   timeout: 6000, //タイムアウトは6秒
      //   maximumAge: 0, //キャッシュはさせない
      // };
      //現在位置の取得
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          //店舗の検索を行う。
          this.searchStore(position);
        }, (error) =>{
          //位置情報取得時にエラーが起きた場合の処理を行う。
          if( error.code == 1 ){
            if(!alert('位置情報取得が許可されていません。')){
              this.$router.push({path: '/'});
            }
          } else if( error.code == 2 ) {
            if(!alert('位置情報取得に失敗しました。')){
              this.$router.push({path: '/'});
            }
          } else {
            if(!alert('位置情報取得に失敗しました。')){
              this.$router.push({path: '/'});
            }
          }
        });
      } else {
        alert("本ブラウザではGeolocationが使えません");
      }
    },
    searchStore(position){
      //店舗の検索を行う。
      this.currentLat = position.coords.latitude;
      this.currentLng = position.coords.longitude;
      // apiを叩く
      let apiurl = "https://jov6ym4yl7.execute-api.ap-northeast-1.amazonaws.com/prod/search-shop"
      axios.post(apiurl,{lat: this.currentLat, lng: this.currentLng, range: this.range}).then((response) => {
        //リストの表示順を初期化する。
        this.viewNum = 0;
        // 取得したデータをオブジェクトに変換する
        const data = JSON.parse(JSON.stringify(response.data.body));
        // 店データを格納
        this.storeData = JSON.parse(data)["results"]["shop"];
        if(this.storeData.length > 0){
          //取得した店舗データのリストをランダムに並べ替える。
          for(let i = 0; i < this.storeData.length; i++){
            // リストのサイズを引数にrandom関数を生成
            let random = Math.floor(Math.random() * this.storeData.length);
            let tmp = this.storeData[i];
            this.storeData[i] = this.storeData[random];
            this.storeData[random] = tmp;
          }
          //店舗詳細画面の店舗データを登録する。
          this.setStore();
        }else{
          //最大範囲でも見つからなかった場合
          if (this.range === 5){
            alert("店舗が検索できませんでした。");
            //店舗が検索できなかった場合、ホーム画面に遷移する。
            this.$router.push({path: '/'});
          } else {
            //検索範囲を1段階上げる
            this.range++;
            this.searchStore(position);
          }
        }
      }).catch((error) => {
        //多分ここでエラーハンドリング？
        console.log(error);
      });
    },
    setStore(){
      //店舗詳細画面に表示する店舗データを登録する。
      this.storeId = this.storeData[this.viewNum].id;
      this.name = this.storeData[this.viewNum].name;
      this.address = this.storeData[this.viewNum].address;
      this.genreCatch = this.storeData[this.viewNum].genre.catch;
      this.storeCatch = this.storeData[this.viewNum].catch;
      this.budgetAverage = this.storeData[this.viewNum].budget.average;
      this.photo = this.storeData[this.viewNum].photo.pc.l;
      this.storeLat = this.storeData[this.viewNum].lat;
      this.storeLng = this.storeData[this.viewNum].lng;
      let distance = Math.round(this.calcDistance(this.currentLat, this.currentLng, this.storeLat, this.storeLng) * 10)  / 10;
      if(distance >= 1.0){
        this.showDistance = distance + 'km';
      }else{
        this.showDistance = (distance * 1000) + 'm';
      }
      this.buttonShowFlag = true;
      this.viewNum++;
    },
    calcDistance(lat1, lng1, lat2, lng2){
      lat1 *= Math.PI / 180;
      lng1 *= Math.PI / 180;
      lat2 *= Math.PI / 180;
      lng2 *= Math.PI / 180;
      return 6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2));
    }
  }
}
</script>

<style lang="scss" scoped>

.clickButton{
  width : 150px;
  margin : 8px 0px;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  border-radius: 4px;
  color: #ffffff;
  background-color: #e88b01;
  // background-image: linear-gradient(45deg, #FFC107 0%, #ff8b5f 100%);
  border: 3px solid #e88b01;
}

.btn-gradient-3d-orange:active {
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  border-bottom: none;
}

.width{
  width : 100%;
}

.main {
  text-align: center;
}

</style>

<template>
  <div class="home">
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSclFhGLlISkc-oTBWq75G8D9amdfDaKzJKjK7iwUKwV1X38Bg/viewform?embedded=true"
    width="100%" height="530" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>

    <iframe id="twitter-widget-0" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true" class="twitter-follow-button twitter-follow-button-rendered" style="position: static; visibility: visible; width: 136px; height: 20px;" title="Twitter Follow Button" src="https://platform.twitter.com/widgets/follow_button.f88235f49a156f8b4cab34c7bc1a0acc.en.html#dnt=false&amp;id=twitter-widget-0&amp;lang=en&amp;screen_name=finalchoice4&amp;show_count=false&amp;show_screen_name=true&amp;size=m&amp;time=1628866952161" data-screen-name="finalchoice4"></iframe>

  </div>
</template>

<!-- <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> -->
<script>
export default {
  name: 'Home',
  mounted() {
  },
  methods: {
    // TODO ボタンが押下された場合にstoreDetails画面に遷移する
    clickGotoStoreDetails() {
      this.$router.push({path: '/store-details'});
    },
  }
}
</script>

<style lang="scss" scoped>

.home {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.tap-message {
  padding: 2rem;
  margin: 0 auto;
}

.clear-decoration {
    border: none;  /* 枠線を消す */
    outline: none; /* クリックしたときに表示される枠線を消す */
    background: transparent; /* 背景の灰色を消す */
}
.twitter {
    position: relative;
    height: 20px;
    box-sizing: border-box;
    padding: 1px 8px 1px 6px;
    background-color: #1b95e0;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;
}
</style>

<template>
  <div class="not-found">
    <div id="lottie" ref="lottie"></div>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import animationData from '@/assets/4339-not-found.json';

export default {
  mounted() {
    document.title = '404 Not Found'
    lottie.loadAnimation({
      container: this.$refs.lottie, // document.getElementbyId('lottie') などでも OK
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData,
    });
  }
}
</script>

<style lang="scss" scoped>
.not-found {
  max-width: 800px;
  margin: 0 auto;
}
</style>